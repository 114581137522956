import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { UIModule } from '@bannerflow/ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TermsConditionsContentComponent } from './views/components/terms-conditions-dialog/terms-conditions-content/terms-conditions-content.component';
import { TermsConditionsDialogComponent } from './views/components/terms-conditions-dialog/terms-conditions-dialog.component';
import { VideoListComponent } from './views/components/video-list/video-list.component';
import { DragDirective } from './views/shared/directive/drag-drop.directive';
import { MainLayoutComponent } from './views/shared/main-layout/main-layout.component';
import { OopsComponent } from './views/shared/oops/oops.component';
import { VideoUploadContainerComponent } from './views/video-upload-container/video-upload-container.component';
import { VideoUploadContentComponent } from './views/video-upload-content.component';
import { AppErrorHandler } from './core/error-handlers/app-error-handler';
import { provideAuth0 } from '@auth0/auth0-angular';
import { ConfigService } from './core/config/config.service';
import {
  provideSentinelService,
  withLoggerConfig,
  withNewRelic,
} from '@bannerflow/sentinel';
import { NewRelicConfig } from './core/config/new-relic/new-relic.config';

@NgModule({
  declarations: [
    AppComponent,
    VideoUploadContentComponent,
    VideoListComponent,
    MainLayoutComponent,
    TermsConditionsContentComponent,
    TermsConditionsDialogComponent,
    OopsComponent,
    DragDirective,
    VideoUploadContainerComponent,
  ],
  imports: [
    UIModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },

    provideAuth0({
      domain: ConfigService.config.IDP_URL,
      clientId: ConfigService.config.CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://bannerflow.com/resources/',
        scope: 'openid profile email offline_access videoUploadApi',
      },
      httpInterceptor: {
        allowedList: [`${ConfigService.config.API_URL}*`],
      },
      useRefreshTokens: true,
    }),
    provideSentinelService(
      withNewRelic({
        ...NewRelicConfig.init(),
      }),
      withLoggerConfig({
        level: 'debug',
        production: ConfigService.isProduction(),
        sandbox: ConfigService.isSandbox(),
        stage: ConfigService.getStage(),
      }),
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
