import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IVideoResponse } from 'src/app/shared/models/video.model';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class VideoApiService {
  public videoUploadApiUrl = `${ConfigService?.config?.API_URL}`;

  constructor(private http: HttpClient) {}

  /**
   * POST video file
   */
  public uploadVideo(
    fileContent: Uint8Array,
    fileName: string,
    fileType: string,
  ): Observable<IVideoResponse> {
    try {
      const formData: FormData = new FormData();
      const blobContnent: Blob = new Blob([fileContent], { type: fileType });

      formData.append('file', blobContnent, fileName);

      const response: any = this.http.post(
        `${this.videoUploadApiUrl}api/videos`,
        formData,
      );

      return response;
    } catch (error) {
      console.log('got some error in service ', error);
    }
  }
}
