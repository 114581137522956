import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'terms-conditions-dialog',
  templateUrl: './terms-conditions-dialog.component.html',
  styleUrls: ['./terms-conditions-dialog.component.scss'],
  standalone: false,
})
export class TermsConditionsDialogComponent implements OnDestroy {
  private callback: Function;

  public async initiate(): Promise<void> {
    return new Promise<any>((resolve: Function) => {
      this.callback = resolve;
    });
  }

  public agreeAndproceed(): void {
    this.callback(true);
  }

  public cancel(): void {
    this.callback(false);
  }

  public ngOnDestroy(): void {
    this.callback(false);
  }
}
